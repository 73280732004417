import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/MDXLayout.tsx";
import SEO from 'components/SEO';
import CauseHero from 'components/CauseHero';
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query BanyUmmatyFR {
    heroImage: file(relativePath: {eq: "project-bany-hero.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Bany UMMATY - Projets pour les orphelins .. Éducation pour tous.. Pour construire un avenir meilleur." description="BANY UMMATY program is an opportunity offered to you to be amongst the ones that the prophet peace and blessings be upon him has described, by standing beside orphans and taking care of them." mdxType="SEO" />
    <CauseHero purpose="bany-ummaty" title="Bany UMMATY" text="Projets pour les orphelins .. Éducation pour tous.. Pour construire un avenir meilleur." data={props.data} pagePath={props.path} mdxType="CauseHero" />
    <div className="p-wrapper">
      <h2>{`Pour les orphelins de la Oumma`}</h2>
      <p>{`Le Messager d'Allah (que Le Salut et La Paix d'Allah soient sur lui) a dit : `}<strong parentName="p">{`« Moi et celui qui prend en charge un orphelin seront comme ceci au Paradis »`}</strong>{` ; et il rejoignit l'index et le majeur.`}</p>
      <p>{`Le programme BANY UMMATY est une opportunité qui vous est offerte pour partie de ceux qui ont été décrits par le Prophète (Paix et Salut soient sur lui) en soutenant les orphelins et en prenant soin d'eux. Le programme BANY UMMATY comprend la construction et l'équipement complet d'orphelinats (incluant les dortoirs, classes, bibliothèques, sanitaires, aires de jeux et de sport, etc.). Nous rénovons également les installations endommagées existantes et leur fournissons du mobilier et du soutien afin de pouvoir faire fonctionner ces structures tout au long de l'année.`}</p>
      <h2>{`Education pour tous`}</h2>
      <p>{`À travers le programme BANY UMMATY, nous soutenons également l'éducation des enfants de notre Oumma. Nos projets majeurs comprennent la construction de nouvelles écoles, avec toutes les installations et équipements nécessaires. D'autres projets de nature différentes sont également mené pour soutenir directement les enfants dans le besoin et leur permettre de poursuivre leurs études. Cela inclut l'achat et la distribution de mobilier scolaire, de cartables et de toutes les fournitures nécessaires à leur éducation. Ces enfants sont les enfants de notre Oumma. Avec votre soutien, nous pouvons les aider à construire un avenir meilleur.`}</p>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      